<template>
  <div class="page">
    <div v-if="show && show1">
      <img src="@/assets/img/info_bg.png" alt="" style="width: 100%" />
    </div>

    <div class="main" v-if="show">
      <div class="title">导师列表</div>
    </div>
    <div class="ExpertList" v-if="!show">
      <ExpertList />
    </div>
  </div>
</template>

<script>
//time: 2021/1/20
import ExpertList from "./ExpertList";

export default {
  name: "Info",
  components: {
    ExpertList,
  },
  data() {
    return {
      ExpertInfo: [],
      show: false,
      show1: false,
    };
  },
  created() {
    this.ExpertInfo = JSON.parse(localStorage.getItem("ExpertInfo"));
    if (this.ExpertInfo.parent_id == 0) {
      this.show = false;
    } else {
      this.show = true;
    }
    let host = window.location.host;
    let state = host.indexOf("xadmin.bkgaoshou.com");
    if (state == -1) {
      this.show1 = false;
    } else {
      this.show1 = true;
    }
  },
  
};
</script>

<style lang="less" scoped>
.brief {
  /*font-size: 14px;*/
  margin-top: 10px;

  p {
    text-indent: 2rem;
    line-height: 25px;
    letter-spacing: 1px;
  }
}

.edit {
  /*font-size: 14px;*/
  color: #cfcfcf;
  text-align: right;
}

.card {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  .item {
    width: 33%;
    background: white;
    padding: 30px 90px;

    .left {
      display: inline-block;
      width: 52px;
      margin-right: 3px;
    }
    .right {
      display: inline-block;
      width: 109px;
      font-size: 21px;
      font-weight: bold;
      line-height: 46px;
      vertical-align: top;
      color: red;
    }
  }
}

.expert_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .item {
    background: white;
    margin-top: 20px;
    width: 49.5%;

    .content {
      padding: 15px;
      border: 1px solid rgb(172, 172, 172);
      height: 150px;

      .left {
        display: inline-block;
        width: 100px;
        height: 120px;
        vertical-align: top;
        overflow: hidden;
        margin-right: 15px;

        img {
          width: 100%;
        }
      }

      .right {
        vertical-align: top;
        width: 320px;
        display: inline-block;

        .name {
          span:first-child {
            margin-right: 10px;
          }

          margin-bottom: 10px;
        }

        .brief {
          text-indent: 2rem;
          /*font-size: 14px;*/
          letter-spacing: 1px;
        }
      }

      .add {
        text-align: center;
        line-height: 120px;
        font-size: 120px;
        font-weight: 100;
      }
    }

    .del {
      color: #cfcfcf;
      /*font-size: 14px;*/
      line-height: 40px;
      text-align: right;

      span {
        padding: 0 10px;
      }
    }
  }
}
</style>